import { AppState } from './types'
import { GetterTree } from 'vuex'
import { Vector2, Vector3 } from 'three'
import { parseURI } from '@/vue/modules/fn'
import { US_COUNTRY, Hash, Scenes } from '@/constants'

export default <GetterTree<AppState, any>>{
  scene: ({ scene }) => scene,
  locale: ({ locale }) => locale,
  scenes: ({ scenes }) => scenes,
  portal: ({ portal }) => portal,
  fetched: ({ fetched }) => fetched,
  started: ({ started }) => started,
  hotspot: ({ hotspot }) => hotspot,
  welcome: ({ welcome }) => welcome,
  progress: ({ progress }) => progress,
  complete: ({ complete }) => complete,
  tutorial: ({ tutorial }) => tutorial,
  settings: ({ settings }) => settings,
  dpr: ({ router: { to } }) => parseFloat(to.query.dpr),
  gui: ({ router: { to } }) => to.query.gui !== undefined,
  stats: ({ router: { to } }) => to.query.stats !== undefined,
  debug: ({ router: { to } }) => to.query.debug !== undefined,
  specs: ({ router: { to } }) => to.query.specs !== undefined,
  native: ({ router: { to } }) => to.query.lang !== undefined,
  devcam: ({ router: { to } }) => to.query.devcam !== undefined,
  product: (_, { scene }) => (Scenes.PORCH === scene ? 'gardenia' : 'jasmine'),
  retailer: ({ retailers, router: { to } }) =>
    US_COUNTRY ? retailers[Hash.SEPHORA] : retailers[to.hash] || retailers[Hash.DEFAULT],
  //shoplink: (_, { native, retailer: { shop } }) => (shop ? parseURI(shop, native) : undefined), // shop ? parseURI(shop, getUTMParams(shop, native)) : undefined,
  shoplink:
    (_, { native, retailer: { shop } }) =>
    (product: string) =>
      shop ? parseURI(shop[product], native) : undefined,
  bounding: ({ settings, scene }) => settings.bounding[scene] || { size: 0, depth: 0 },
  hotspots: ({ collection, scene }) =>
    collection[scene]
      ? collection[scene].hotspots.map(({ uid, uids, gtm, type, link, media, action, iframe, product, position, hiddenOnChina }) => ({
          position: new Vector3().copy(position),
          projection: new Vector2(),
          gtm,
          uid,
          uids,
          type,
          link,
          media,
          action,
          iframe,
          product,
          hiddenOnChina,
        }))
      : [],
}
