var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModeProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ mode }){return [_c('StateProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ settings: { sprinkles, flowers, lights, sounds, glow } }){return [_c('TrackingProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ GTMEventCategory, GTMEventAction, GTMScenes, eventPush }){return [_c('ResizeProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ viewport }){return [_c('PointerProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ normalized, spreaded, coords, delta }){return [_c('MixerProvider',{attrs:{"connect":_vm.complete,"playlist":sounds},scopedSlots:_vm._u([{key:"default",fn:function({ audioListener, toggleSound, SoundStore, initSound, playSound, unmute, muted, mute }){return [_c('VisibilityProvider',{on:{"visible":unmute,"hidden":mute},scopedSlots:_vm._u([{key:"default",fn:function({ visible }){return [_c('ThreeProvider',{attrs:{"audioListener":audioListener},scopedSlots:_vm._u([{key:"default",fn:function({ pixelRatio, snapTarget, syncDolly, snapDolly, bounding }){return [_c('section',{class:{ devcam: _vm.devcam, debug: _vm.debug },attrs:{"id":"root"}},[_c('Canvas',{attrs:{"hotspots":_vm.hotspots,"viewport":viewport,"zoom":_vm.needsOverlay,"debug":_vm.devcam,"mouse":{
                      normalized,
                      spreaded,
                      delta,
                    }}},[_c('transition',{attrs:{"css":false,"appear":true,"mode":'out-in'},on:{"enter":_vm.sceneEnter,"leave":_vm.sceneLeave}},[(_vm.complete)?_c(_vm.sceneComponent,{key:`${_vm.currentScene}-scene`,ref:"scene",tag:"component",attrs:{"overlay":_vm.needsOverlay,"hotspots":_vm.hotspots,"bounding":bounding,"helpers":_vm.debug,"muted":muted}}):_vm._e()],1),_c('Starlight',{attrs:{"dpr":pixelRatio}}),(_vm.complete)?_c('Sprinkles',{attrs:{"hotspot":_vm.currentHotspot,"position":sprinkles.position,"reveal":sprinkles.reveal.value}}):_vm._e(),_c('Glow',{attrs:{"flare":glow.flare,"ratio":glow.ratio,"strength":glow.strength.value,"reveal":glow.reveal.value}}),(_vm.complete)?_c('Flowers',{attrs:{"translate":flowers.translate,"rotation":flowers.rotation,"scale":flowers.scale.value,"reveal":flowers.reveal.value}}):_vm._e(),_c('Lights',{attrs:{"directional":lights.directional,"ambient":lights.ambient,"helpers":_vm.debug}})],1),(_vm.needsLoader)?_c('Loader',{key:`${_vm.currentScene}-loader`,attrs:{"scene":_vm.currentScene}}):_vm._e(),_c('Dolly',{key:`${_vm.currentScene}-dolly`,attrs:{"bounding":bounding,"viewport":viewport,"snap":snapTarget},on:{"update":syncDolly}}),(_vm.active)?_c('Hotspots',{key:`${_vm.currentScene}-hotspots`,attrs:{"prevent":_vm.needsOverlay,"landing":_vm.sceneLanding,"collection":_vm.hotspots,"scene":_vm.currentScene,"viewport":viewport,"mouse":coords,"mode":mode},on:{"next":() => {
                        eventPush({
                          eventCategory: GTMEventCategory.DOORS,
                          eventAction: GTMScenes[_vm.currentScene],
                        })
                        playSound([SoundStore.HOTSPOT, SoundStore.TRANSITION])
                        _vm.gotoNextScene()
                      },"select":(hotspot) => {
                        _vm.setCurrentHotspot(hotspot)
                        snapDolly(hotspot.position.x)
                        playSound(SoundStore.HOTSPOT)
                      }}}):_vm._e(),(_vm.active)?_c('Navigator',{key:`${_vm.currentScene}-navigator`,attrs:{"landing":_vm.sceneLanding,"scene":_vm.currentScene,"hotspots":_vm.hotspots},on:{"snap":snapDolly}}):_vm._e(),(_vm.started)?_c('Header',{attrs:{"muted":muted,"mode":mode},on:{"scene":(scene) => {
                        _vm.setScene(scene)
                        playSound([SoundStore.HOTSPOT, SoundStore.TRANSITION])
                        eventPush({
                          eventCategory: GTMEventCategory.ENVIRONMENT_MENU,
                          eventAction: GTMScenes[scene],
                        })
                      },"shop":({ retailer }) => {
                        eventPush({
                          eventCategory: GTMEventCategory.SHOP_FLORA,
                          eventAction: GTMScenes[_vm.currentScene],
                          eventLabel: retailer,
                        })
                      },"sound":(muted) => {
                        toggleSound(muted)
                        eventPush({
                          eventCategory: GTMEventCategory.TOGGL_AUDIO,
                          eventAction: muted ? GTMEventAction.AUDIO_OFF : GTMEventAction.AUDIO_ON,
                        })
                      }}}):_vm._e(),(_vm.needsWelcome)?_c('Welcome',{on:{"elapsed":function($event){return _vm.setWelcomeState(true)}}}):_vm._e(),(_vm.needsTutorial)?_c('Tutorial',{on:{"confirm":function($event){return _vm.setTutorialState(true)}}}):_vm._e(),(_vm.needsLanding)?_c('Landing',{on:{"start":({ retailer }) => {
                        _vm.starExperience()
                        initSound([SoundStore.HOTSPOT, SoundStore.TRANSITION])
                        eventPush({ eventCategory: GTMEventCategory.START_EXPLORING, eventLabel: retailer })
                      }}}):_vm._e(),(_vm.needsOverlay)?_c('Overlay',{attrs:{"muted":muted,"visible":visible,"viewport":viewport,"scene":_vm.currentScene,"hotspot":_vm.currentHotspot},on:{"pause":function($event){visible && !muted && unmute()},"play":(video) => {
                        visible && !muted && mute()
                        eventPush({
                          eventCategory: _vm.currentHotspot.gtm,
                          eventAction: GTMEventAction.PLAY,
                          eventLabel: video || '',
                        })
                      },"shop":({ retailer }) => {
                        eventPush({
                          eventCategory: _vm.currentHotspot.gtm,
                          eventAction: GTMEventAction.SHOP,
                          eventLabel: retailer,
                        })
                      },"vto":() => {
                        eventPush({
                          eventCategory: _vm.currentHotspot.gtm,
                          eventAction: GTMEventAction.VTO,
                        })
                        _vm.setPortal(true)
                      },"ig":({ retailer }) => {
                        eventPush({
                          eventCategory: _vm.currentHotspot.gtm,
                          eventAction: GTMEventAction.LAUNCH_IG,
                          eventLabel: retailer,
                        })
                      },"download":() => {
                        eventPush({
                          eventCategory: _vm.currentHotspot.gtm,
                          eventAction: GTMEventAction.DOWNLOAD,
                          eventLabel: _vm.currentRetailer.retailer,
                        })
                      },"back":() => {
                        eventPush({
                          eventCategory: _vm.currentHotspot.gtm,
                          eventAction: GTMEventAction.BACK,
                        })
                        _vm.setCurrentHotspot(null)
                      }}}):_vm._e(),(_vm.portal)?_c('Portal',{attrs:{"viewport":viewport,"scene":_vm.currentScene,"hotspot":_vm.currentHotspot},on:{"update":(look) => {
                      eventPush(look)
                    },"back":() => {
                        _vm.setCurrentHotspot(null)
                        _vm.setPortal(false)
                      }}}):_vm._e(),_c('Footer',{on:{"privacy":() => {
                        eventPush({
                          eventCategory: GTMEventCategory.COOKIE_PRIVACY,
                          eventAction: GTMEventAction.PRIVACY,
                        })
                      },"cookies":() => {
                        eventPush({
                          eventCategory: GTMEventCategory.COOKIE_PRIVACY,
                          eventAction: GTMEventAction.COOKIE,
                        })
                      }}})],1)]}}],null,true)})]}}],null,true)})]}}],null,true)})]}}],null,true)})]}}],null,true)})]}}],null,true)})]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }