import axios, { AxiosResponse } from 'axios'
import { isVariant } from '@/services/mode'

export const fetchRetailersData = (): Promise<AxiosResponse<any>> => {
  return axios.get(`${process.env.BASE_URL}data/retailers${isVariant ? '_variant' : ''}.json?t=${Date.now()}`)
}

export const fetchCollectionData = (): Promise<AxiosResponse<any>> => {
  return axios.get(`${process.env.BASE_URL}data/collection.json?t=${Date.now()}`)
}
