export const WELCOME_DURATION = 3.5
export const REVEAL_DURATION = 3.5
export const LEAVE_DURATION = 3.8
export const ENTER_DURATION = 3

export const US_COUNTRY = (window as any).US_COUNTRY

export const MediaQ = {
  XS: 360,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
}

export const AppMode = {
  DEFAULT: 'default',
  VARIANT: 'variant',
  CHINA: 'china',
}

export const Hash = {
  DEFAULT: '#default',
  SEPHORA: '#sephora',
  GUCCI: '#store',
}

export const Scenes = {
  BEDROOM: 'bedroom',
  MAKEUP: 'makeup',
  PORCH: 'porch',
}

export const Events = {
  GL: {
    RENDER: 'gl:render',
    RESIZE: 'gl:resize',
    REVEAL: 'gl:reveal',
    ACTIVE: 'gl:active',
    COMPILE: 'gl:compile',
  },
  GUI: {
    CHANGE: 'gui:change',
  },
}

export const Widgets = {
  AUDIO_PLAYER: 'audio-player',
  IMAGE_POSTER: 'image-poster',
  IMAGE_GALLERY: 'image-gallery',
  IFRAME_PORTAL: 'iframe-portal',
  VIDEO_CAROUSEL: 'video-carousel',
  MOBILE_LINK: 'mobile-link',
}

export const WidgetActions = {
  NONE: 'none',
  SHOP: 'shop',
  VTO: 'vto',
  IG: 'ig',
}
